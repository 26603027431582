ion-slides {
  height: 100%;

  ion-slide{
    display: block!important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow-y: scroll;
    scroll-snap-type: y mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    .product-category{
      height: 100%;
      scroll-snap-align: center;
      position: relative;

      ion-img{
        height: 100%;
        object-fit: cover;
      }

      .img-overlay{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.3);

        h2{
          color: #fff;
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }
}