.payment-method-container {
  .process-name{
    margin-bottom: 25px;
  }

  .payment-methods-list {
    .payment-method {
      box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
      padding: 20px;
      border-radius: 7px;
      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }

      ion-img {
        width: 40px;
        margin-right: 25px;
      }

      ion-label{
        h3{
          font-weight: 600;
        }

        p{
          font-size:12px;
        }
      }
    }
  }
}