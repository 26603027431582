.product-detail-container {
  position: relative;

  >ion-slides{
    padding-bottom: 100px !important;

    ion-slide{
      ion-slides{
        .swiper-pagination-bullets {
          right: unset!important;
          left: 16px;
        }

        .swiper-pagination-bullet{
          border: 1px solid #000;
        }
      }
    }

    ion-slide {
      ion-img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .product-colors{
    padding: 14px;
    position: absolute;
    bottom: 102px;
    right: 0;
    left: 0;
    z-index: 3;
    background: rgba(0,0,0,0.05);

    .product-color{
      margin-right: 15px;
      padding: 3px;

      &:last-of-type{
        margin-right: 0;
      }

      &.active{
        box-shadow: rgb(255,255,255) 0 0 0 2px;
      }

      span{
        width: 30px;
        height: 7px;
        border: 1px solid rgb(145, 145, 145);
        display: block;
      }
    }
  }
}

