
body {
  font-family: "custom font";
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6, p {
  letter-spacing: 0;
  margin: 0;
}

.d-flex {
  display: flex !important;
}

.process-name{
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 18px;
}

ion-menu {
  ion-header {
    ion-title {
      font-weight: 700;
      font-size: 17px;
      color: var(--ion-color-medium);
      text-align: left;
    }

    ion-icon {
      font-size: 30px;
      margin-right: 10px;
    }
  }

  ion-list.filter-list {
    margin-bottom: 70px;

    ion-item-divider {
      ion-label {
        font-weight: 700;
      }
    }

    .filter-name {
      padding: 16px;

      ion-label {
        font-weight: 600;
        font-size: 14px;
      }
    }

    ion-item {
      .list-lines-none {
        width: 100%;

        .item {
          --border-width: 0;
          --inner-border-width: 0;
          --padding-start: 0;
          --min-height: 38px;

          ion-label {
            margin: 7px 0;

            .product-color{
              width: 9px;
              height: 9px;
              border-radius: 50%;
              margin-right: 15px;
              border: 1px solid rgb(145, 145, 145);
              display: inline-block;
            }
          }

          ion-checkbox {
            margin: 7px 17px 7px 0;
            --border-width: 1px;
            --border-radius: 0;
          }
        }
      }
    }
  }

  .filter-btn{
    position: fixed;
    bottom: 0;
    padding: 16px;
    width: 100%;
    background: #fff;
  }
}

.main-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.17);

  ion-toolbar {

    ion-title {
      font-weight: 700;
      font-size: 17px;
      color: var(--ion-color-primary);
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: 0;
      bottom: 0;
      text-transform: uppercase;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 55px;
    }

    ion-img {
      width: 35px;
      margin-left: 15px;
    }

    >ion-icon {
      font-size: 22px;
      --color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.4);

      &:first-of-type {
        margin-right: 16px;
      }
    }

   ion-buttons{
     margin-right: 18px;

     ion-icon{
       font-size: 22px;
       --color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.4);
     }

     ion-badge{
       position: absolute;
       left: -5px;
       bottom: -5px;
       border-radius: 50%;
       width: 15px;
       height: 15px;
       padding: 0;
       font-size: 10px;
       line-height: 16px;
     }
   }
  }

  ion-segment {
    margin-bottom: -1px;

    ion-segment-button {
      --color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.4);


      ion-label {
        font-weight: 700;
        letter-spacing: 0;
      }
    }
  }
}

.saint-btn {
  --border-radius: 0;
  height: 44px;
  font-weight: 700;
  margin: 0;

  ion-icon {
    margin-right: 15px;
  }
}

.saint-input {
  border: 1px solid #adadad;
  margin-bottom: 20px;
  font-size: 14px;

  input::placeholder{
    font-size: 14px;
  }
}

.btn-link{
  --background: transparent !important;
  --color: var(--ion-color-primary);
  --box-shadow: none;
  font-weight: 700;
  text-transform: capitalize;
  --border-radius: 0;
}

.cart-total {
  position: fixed;
  bottom: 0;
  background: #fff !important;
  z-index: 2;
  width: 100%;
  padding: 16px 21px!important;

  .total-price {
    display: flex;
    margin-bottom: 6px;

    span {
      font-size: 16px;
      font-weight: 700;
    }
  }
}