.cart-container {
  padding-bottom: 100px!important;

  .cart-row {
    padding: 22px 0;
    border-bottom: 1px solid #e3e3e3;

    &:last-of-type {
      border-bottom: none !important;
    }

    &:first-of-type {
      padding-top: 0 !important;
    }

    ion-img{
      object-fit: cover;
      height: 100%;
    }

    h5 {
      font-weight: 700;
      font-size: 13px;
      margin-bottom: 10px;
      padding-right: 30px;

    }

    .product-detail {
      position: relative;
      flex-direction: column;

      ion-icon,ion-checkbox{
        position: absolute;
        right: 5px;
        top: 5px;
      }

      ion-checkbox{
        --border-width: 1px;
        --border-radius: 0;
      }

      h6 {
        font-size: 12px;
        margin-bottom: 6px;
        font-weight: 600;
      }

      .product-price {
        font-size: 14px;
        font-weight: 700;
      }

      .bottom-row {
        width: 100%;

        .quantity,.size {
          display: flex;
          //width: 120px;
          border: 1px solid #c7c7c7;
          padding: 4px 8px;
          border-radius: 2px;

          ion-label {
            font-size: 12px;
            font-weight: 600;
            padding-top: 1px;
          }

          ion-select {
            font-size: 13px;
            padding: 0 0 0 10px;
            font-weight: 600;
            height: 22px;
            z-index: 1;
          }
        }
      }
    }
  }
}

.custom-interface {
  .select-interface-option {
    padding-inline-end: 0;
    padding-inline-start: 0;
    min-width: 45px;

    span {
      width: 100%;
      justify-content: center;
      padding: 8px;
    }
  }

  .action-sheet-group {
    display: flex;
    overscroll-behavior-x: contain;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

