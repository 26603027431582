.my-account-container {
  .existing-customer {
    padding-top: 20px;
    margin-bottom: 40px;

    .customer-type {
      ion-icon {
        margin-bottom: 25px;
        font-size: 30px;
      }
    }

    h4 {
      font-size: 18px;
      margin-bottom: 35px;
      font-weight: 600;
    }

    .saint-btn {
      margin-bottom: 20px;

      &:nth-of-type(2) {
        --background: #4267B2;
        margin-bottom: 0;
      }
    }
  }
}

.new-customer {
  h4 {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
  }
}