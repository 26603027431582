.sub-category-header {
  ion-toolbar {
    box-shadow: 0px -1px 3px var(--ion-color-primary);
  }

  .filter-bar {
    padding: 4px;
    background: #fff !important;

    ion-button {
      &:nth-of-type(2) {
        border-left: 1px solid #cfcfcf;
      }
    }
  }
}

.sub-category-container {
  .product.large-size{
    .product-image{
      height: 420px;

      ion-img{
        height: 100%;
        object-fit: cover;
      }
    }
  }

  ion-slide.product{
    padding: 10px!important;

    .product-image{
      height: 200px;

      ion-img{
        height: 100%;
        object-fit: cover;
      }
    }

    .product-description{
      padding: 10px 0;
    }
  }

  .product {
    padding: 0;

    &.small-size:nth-of-type(odd){
      padding-right: 5px;
    }

    &.small-size:nth-of-type(even){
      padding-left: 5px;
    }

    &.small-size {
      .product-image {
        height: 230px;

        ion-img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .product-description {
      padding:  10px;
      text-align: left;

      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 12px;

        &:nth-of-type(2){
          margin-bottom: 12px;
          font-size: 11px;
        }

        &:nth-of-type(3) {
          font-size: 11px;
        }
      }

      span{
        font-size: 11px;
      }

      .product-colors{
        margin: 14px 0 14px 3px;

        span{
          width: 9px;
          height: 9px;
          border-radius: 50%;
          margin-right: 15px;
          border: 1px solid rgb(145, 145, 145);

          &.active{
            box-shadow: rgb(255, 255, 255) 0 0 0 2px, rgb(145, 145, 145) 0 0 0 3px;
          }
        }
      }
    }
  }
}