.phone-input {
  margin-bottom: 30px;

  ion-select {
    border: 1px solid #adadad;
    font-size: 14px;
    padding: 9px 8px;
    margin-right: 10px;
    width: 110px;
  }

  ion-input {
    margin-bottom: 0 !important;
  }
}