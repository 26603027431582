.shipping-container {
  --padding-bottom: 100px!important;

  .cart-container {
    padding: 0 !important;
    margin-bottom: 30px;

    .product-name {
      margin-bottom: 27px;
    }

    .cart-row {
      padding: 14px 0;

      .product-img {
        padding-left: 0;
      }

      .product-detail {
        padding-left: 10px;

        .top-row {
          h5 {
            padding-right: 0;
          }
        }

        .bottom-row {
          h6 {
            margin-bottom: 0;
            padding-top: 5px;
          }

          .product-price {
            margin-right: 0;
          }
        }
      }
    }
  }

  .shipping-type-container, .shipping-address-container {
    margin-bottom: 15px;

    .process-name {
      margin-bottom: 2px !important;
    }
  }

  .payment-method-container {
    margin-bottom: 30px;

    .product-name {
      margin-bottom: 27px;
    }

    .promo-code {
      .saint-btn {
        height: 39px;
      }

      .saint-input {
        border-right: 0;
        margin: 0;
      }
    }
  }

  .order-detail-container {
    .order-detail {
      border-bottom: 1px solid #959595;
      border-top: 1px solid #959595;
      padding: 8px 0;

      .detail-item {
        margin-bottom: 5px;

        span {
          font-size: 13px;
          font-weight: normal;
        }
      }

      .order-total {
        margin-top: 10px;


        span {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .privacy-policy {
    ion-item {
      --padding-start: 0;
      --inner-padding-end:0;

      ion-label{
        font-size: 13px;
      }
    }

    .privacy-policy-links{
      a{
        display: block;
        font-size: 13px;
        &:first-of-type{
          margin-bottom: 7px;
        }
      }
    }
  }
}

.order-alert{
  text-align: center;

  img{
    width: 60px;
    height: 60px;
  }

  p{
    font-weight: 600;
    color: #000000;
    margin: 8px 0;
  }
}
