.register-form {
  .gender-selection {
    display: flex;
    margin-bottom: 20px;

    ion-segment {
      border: 1px solid #adadad;
      height: 38px;

      ion-segment-button{
        min-height: 38px;
      }
    }

    .segment-button-checked {
      color: #fff;
      --indicator-height:40px;
    }

    ion-icon {
      font-size: 25px;
      padding: 0 12px;
    }
  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end:0;
  }
}