
ion-tab-button{
  --color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  flex-basis: auto;

  ion-icon{
    font-size: 17px;
    margin-bottom: 4px;
  }

  ion-img{
    width: 18px;
    margin-bottom: 4px;
  }

  ion-label{
    font-size: 10px;
    font-weight: 700;
  }
}