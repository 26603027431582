
.shipping-list{
  ion-item{
    --padding-start: 0;
    --inner-padding-end:0;

    &:last-of-type{
      --inner-border-width: 0;
    }

    ion-icon{
      z-index: 9999;
    }

    ion-radio{
      margin-inline-start: 22px;
    }

    ion-label{
      h2{
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 15px;
      }

      p{
        font-style: italic;
      }
    }
  }
}