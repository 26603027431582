.process-list {
  margin-bottom: 30px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      flex-basis: auto;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      margin-right: 10px;
      padding-bottom: 14px;
      padding-top: 8px;

      &.active{
        border-bottom: 3px solid var(--ion-color-primary);
      }

      &:last-of-type{
        margin-right: 0;
      }

      ion-img {
        width: 32px;
        height: 32px;
      }
    }
  }
}